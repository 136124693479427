@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@design';
.s-quiz-title {
  font-size: 1.5rem;
}
.s-quiz-subheading {
  font-size: 1.125rem;
}
.s-min-height {
  min-width: 980px;
  @media only screen and (max-width: 1263px) {
    min-width: 900px;
  }
  @media only screen and (max-width: 960px) {
    min-width: auto;
  }
}
